

body {
  height: 100%;
  margin: 0;
}



.demopage {
  overflow: hidden;
  border-radius: 15px;
  border: 3px solid #DFDFDF;
  height: 89vh;
  position: relative;



}


.white-side,
.black-side {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 48%;
  padding: 20px;
  position: absolute;
  background-color: rgb(0, 0, 0);

}

.white-side {
  text-align: center;
  line-height: 1.6;
  font-size: 1.0em;
  background-color: #DFDFDF;
  width: 49%;
  height: 96%;

  .border-black {

    margin-left: 5%;
    margin-right: 5%;
    border: 0.1em solid #000000;
    border-radius: 10px;
    padding: 8px;
  }

  .picturename {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 2px;
    margin-right: 596px;
    text-align: start;


  }


  .texte-intro {
    border-radius: 10px;
    width: 90%;
    height: 775px;

  }

  .picturebook {
    border: 0.1em solid #000000;
    margin-top: 20px;
    width: 85%;

  }

  .page-number-one {
    margin-top: 10px;
  }
}

.number-two {

  margin-bottom: 28px;
}

.black-side {
  right: 0;
  color: #DFDFDF;
  height: 100%;


  .titinecompteur {
    width: 37%;
    margin-right: 50%;
    border-radius: 2%;
    border: 0.1em solid #DFDFDF;

  }

  .kmbook {

    margin-right: 50%;
  }

  .titineechappement {
    width: 56%;
    height: 25vh;
    margin-left: 41%;
    margin-top: -259px;
    border-radius: 10px;
    border: 0.1em solid #DFDFDF;


  }

  .km-desription {
    position: absolute;
    top: 13%;
    margin-left: 43%;
    line-height: 1.6;
    font-size: 1.0em;
    /* Utiliser des unités relatives */
    border-radius: 10px;
    border: 0.1em solid #DFDFDF;
    margin-right: 4%;
    width: 40%;
    height: 29%;
    padding: 16px;
    display: flex;
    align-items: center;
    text-align: center;


  }

  .title-echappement {
    margin-left: 44%;
    margin-top: 52px;
  }

  .echappement-description {
    width: 31%;
    margin-left: -57%;
    margin-top: 1%;
    line-height: 1.6;
    font-size: 1.0em;
    border-radius: 10px;
    border: 0.1em solid #DFDFDF;
    padding: 14px;
    display: flex;
    align-items: center;
    text-align: center;

  }

}



.picturebook {
  width: 90%;
  border-radius: 10px;
  margin-top: 5%;
}

.container-cloche {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 20px;



  .guardian-bell {
    margin-left: 3%;
    display: flex;
    margin-top: -1%;

  }

}

.titine-cloche {
  border-radius: 10px;
  width: 40%;
  margin-top: -38%;
  border: 0.1em solid #000000;


}

.cloche-text {
  margin-left: 45%;
  margin-top: -1%;
  border-radius: 10px;
  border: 0.1em solid #000000;
  width: 44%;
  padding: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  line-height: 1.6;
  height: 28vh;
}



.container-plaque {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-right: 11%;
  margin-top: 2%;

}

.title-plaque {
  margin-right: 7%;
  margin-top: 1%;

}

.titine-plaque {
  width: 41%;
  border-radius: 10Px;
  margin-right: -1%;
  margin-top: -37%;
  border: 0.1em solid #000000;

}

.plaque-text {
  margin-right: 43%;
  margin-top: 5%;
  border-radius: 10px;
  border: 0.1em solid #000000;
  padding: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  line-height: 1.2;
}

.container-filtre {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  margin-right: 10px;

}

.titine-filtre {
  border-radius: 10px;
  width: 45%;
  margin-top: -32%;
  border: 0.1em solid #DFDFDF;

}

.title-filtre {
  margin-right: 9%;


}

.filtre-text {
  margin-left: 5%;
  margin-right: 50%;
  margin-top: 4%;
  border-radius: 10px;
  border: 0.1em solid #DFDFDF;
  padding: 13Px;
  height: 20vh;
  align-items: center;
  display: flex;
  text-align: center;
}

.title-selle {
  margin-right: 35%;
  margin-top: 167px;


}

.text-selle {
  border-radius: 10px;
  border: 0.1em solid #DFDFDF;
  padding: 10Px;
  align-items: center;
  display: flex;
  text-align: center;
  margin-top: -53px;
  margin-left: 65%;
  height: 20vh;
  margin-right: 15px;

}

.page-number-white-plaque {

  margin-bottom: -8px;
}

.page-number-white-five {
  margin-bottom: -10px;

}

.page-number-black-four {

  margin-bottom: 30px;
}

.page-number-black-six {

  margin-bottom: 33px;
}

.titine-selle {
  width: 58%;
  margin-top: -31%;
  margin-right: 34%;
  border-radius: 10px;
  border: 0.1em solid #DFDFDF;
}



.title-key {
  width: 100%;
  display: flex;
  justify-content: left;
  margin-left: 25%;
  margin-top: -1%;
}

.titine-key {
  margin-right: 55%;
  border-radius: 10px;
  width: 35%;
  margin-top: -10px;
  border: 0.1em solid #000000;

}

.text-key {
  border-radius: 10px;
  border: 0.1em solid #000000;
  padding: 20Px;
  align-items: center;
  display: flex;
  text-align: center;
  margin-left: 40%;
  margin-top: -32%;
  width: 34vh;
}

.title-guidon {
  margin-left: 37%;
  margin-top: 10%;
}

.titine-guidon {
  margin-left: 39%;
  border-radius: 10px;
  width: 53%;
  border: 0.1em solid #000000;
}

.text-guidon {
  border-radius: 10px;
  border: 0.1em solid #000000;
  padding: 20Px;
  align-items: center;
  display: flex;
  text-align: center;
  margin-right: 60%;
  margin-top: -26%;
  width: 34vh;
}

.titine-book {
  margin-top: -1%;
  border-radius: 10px;
  width: 80%;
  border-radius: 10px;
  border: 0.1em solid #fafafa;
}

.date-picture {
  margin-top: 3px;
  margin-right: 70%;
}

.title-book-titine {
  font-size: 200%;

}

.buttonicon {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}



.buttonicon.left {
  left: 10px;
  background-color: #000000;
  color: #DFDFDF;
  cursor: pointer !important;

}

.buttonicon.right {
  right: 10px;
  background-color: #DFDFDF;
  color: #000000;
  cursor: pointer !important;


}

.icon {
  height: 30px;
  margin: 0;

}




.page-number-white,
.page-number-black {
  position: absolute;
  bottom: 20px;
  font-size: 14px;

}

.page-number-white {
  color: black;
}

.page-number-black {
  color: #DFDFDF;
}




/* ipad 13 */
@media only screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {



  .page-number-one {

    
    position: absolute;
    top: 95% !important;

  }

  .picturename {
    position: absolute !important;
    left: 3% !important;
top: 97% !important;
width: 15% !important;

height: 10px !important;
  }

  .text-intro {

    height: 75vh !important;
    width: 100% !important;
   
    h1 {
      margin-top: -2px !important;
    }

    h2 {

      margin-top:-10Px !important;
    }

    p {
font-size: 17px !important;
      margin-top: 31Px !important;
    }
 


  }
  

  .picturebook {
    border: 0.1em solid #000000;
    margin-top: 50px !important;
    height: 45% !important;
    width: 86% !important;
   
  }

  .black-side {
    right: 0;
    color: #DFDFDF;

  }

  .titinecompteur {
    width: 37% !important;
    height: 29vh !important;
    margin-right: 50%;
    border-radius: 2%;
    border: 0.1em solid #DFDFDF;
  }

  .kmbook {
    margin-right: 50% !important;
    margin-top: 55px !important;
  }



  .km-desription {

    height: 22vh !important;
    margin-top: 25px;
    margin-right: 1% !important;


  }

  .title-echappement {
    margin-left: 44%;
    margin-top: 64px !important;

  }

  .echappement-description {
    width: 31%;
    line-height: 1.6;
    font-size: 1.0em;
    border-radius: 10px;
    border: 0.1em solid #DFDFDF;
    padding: 14px;
    display: flex;
    align-items: center;
margin-top: 80px !important;
height: 20vh !important;

  }

  .titineechappement {
    top: 91% !important;
    position: absolute;


  }

  .number-two {

    height: 22Vh;
    margin-top:122% !important;
  }




  .guardian-bell {
    position: absolute;
    left: 1%;
    top: 6%;

  }



  .titine-cloche {
    position: absolute;
    top: 40% !important;
    left: 3px !important;
    width: 40% !important;
    height: 30vh !important;
  }

  .cloche-text {
height: 23vh !important;

    margin-top: 11% !important;

  }

  .title-plaque {
    margin-top: 95Px;
    margin-left: 295px;


  }

  .titine-plaque {
    margin-top: -310px !important;
    margin-left: -70Px;
    width: 40% !important;
    height: 30vh !important;
  }

  .plaque-text {
    margin-top: 20% !important;
    margin-left: 1%;
    padding: 10Px;
    width: 45% !important;
    height: 25vh !important;

  }

  .page-number-white-plaque {

    margin-top: 79px !important;
  }



  .titine-filtre {
    border-radius: 10px;
    width: 45% !important;
    margin-top: -46% !important;
    border: 0.1em solid #DFDFDF;
    margin-left: -20px !important;
  }

  .title-filtre {
    margin-left: 51%;
    margin-top: 100px;

  }



  .filtre-text {
  
margin-top: 10vh !important;
    height: 10vh !important;
    width: 45% !important;
    align-items: center;
    display: flex;
    text-align: center;
  }

  .title-selle {
    margin-right: 32% !important;
    margin-top: 40% !important;


  }

  .text-selle {
    border-radius: 10px;
    border: 0.1em solid #DFDFDF;
    padding: 10Px;
    align-items: center;
    display: flex;
    text-align: center;
    margin-top: -10px !important;
    margin-left: 65%;
    height: 20vh !important;
    margin-right: 15px;

  }

  .titine-selle {

    margin-top: -230px;
  }

  .page-number-black-four {

    margin-top: 67px;
  }







  .title-book-titine {
font-size: 38px;
margin-top: 21px !important;
  }

  .titine-book {
    margin-top: 20px;
    height: 65Vh !important;
    width: 85% !important;

  }

  .date-picture {
margin-top: -19px !important;
    margin-left: -25px !important;
  font-size: 15px !important;
  }

  .page-number-black-six {

    margin-top: 28px;
  }

  .title-key {
    width: 100%;
    display: flex;
    justify-content: left;
    margin-left: 22%;
    margin-top: 1% !important;
  }

  .titine-key {
    margin-right: 55%;
    border-radius: 10px;
    width: 40% !important;
    margin-top: -10px;
    border: 0.1em solid #000000;

  }

  .text-key {
    border-radius: 10px;
    border: 0.1em solid #000000;
    padding: 20Px;
    align-items: center;
    display: flex;
    text-align: center;
    margin-left: 44%;
    margin-top: -47% !important;
    width: 27vh !important;
    height: 22vh !important;
  }

  .title-guidon {
    margin-left: 39%;
    margin-top: 15% !important;
  }

  .titine-guidon {
    margin-left: 39%;
    border-radius: 10px;
    width: 45% !important;
    border: 0.1em solid #000000;
    margin-top: 7% !important;
    height: 23vh !important; 

  }

  .text-guidon {
    border-radius: 10px;
    border: 0.1em solid #000000;
    padding: 20Px;
    align-items: center;
    display: flex;
    text-align: center;
    margin-right: 60%;
    margin-bottom: 55px !important;
    width: 24vh !important;
    height: 21vh !important;
  }

  .page-number-white-five {

    margin-top: 70px !important;
  }

  


}


/* lenovo*/
@media only screen and (min-width: 1280px) and (max-width: 1440px) {


  p.page-number-one {
position: absolute;
   top: 93%;
  margin: -10px;
 
   
  }
  .demopage {
    height: 86vh;
  }

  .border-black {
    height: 34vh;
    width: 83%;
    margin-top: -15px;


    h1 {
      margin-top: -7px;
    }

    h2 {

      margin-top: -24Px;
    }

    p {
font-size: 14px;
      margin-top: -21Px;
    }
 


  }

  .picturebook {
    width: 70%;
height: 42vh;

position: absolute;
   top: 40%;
  
  }

  .picturename {

height: 80vh;
margin-left: 71px;
align-items: end;
font-size: 13px;
position: absolute;
top: 18%;
width: 100px;

  }
 


  .title-book-titine {
  margin-top: -10px;
   
  }

  .titine-book {
    margin-top: -10px;
width: 61%;


  }

  .date-picture {
font-size: 14px;
    margin-left: 124px;
  }

  .page-number-black-six {
   
    margin-top: -20px;
  }


  .title-key {
    width: 100%;
    display: flex;
    justify-content: left;
    margin-left: 22%;
    margin-top: -3%;
  }

  .titine-key {
   
    width: 31%;
  margin-left: 37px;
    position: absolute;
    top: 10%;

  }

  .text-key {
    font-size: 14px;
margin-right: 25px;
height: 30vh;
    width: 45vh;
    position: absolute;
    top: 51%;
  }

  .title-guidon {
   left: 16%;
    position: absolute;
    top:3%;
  }

  .titine-guidon {
    margin-left: 39%;
    width: 45%;
    position: absolute;
    top: 57%;
     
  }

  .text-guidon {
    margin-left: 20px;
    width: 44vh;
    height: 145px;
    
    font-size: 14px;
   
  }

  .page-number-white-five {

position: absolute;
top: 88%;  }


  
  .titinecompteur {
position: absolute;
top: 9%;
height: 39vh;
left: 6%;
  }

  
.kmbook{
  position: absolute;
  top: -3%;
}


  .km-desription{
    font-size: 14px !important;
position: absolute;
top: 8% !important;
  }

  .title-echappement {
    margin-left: 44%;
    position: absolute;
    top: 40%;

  }

  .echappement-description {
 position: absolute;
 top: 54%;
 font-size: 14px !important;
    height: 28vh;
width: 33% !important;

  }

  .titineechappement {
    top: 108%;
    position: absolute;
   


  }

  .number-two {

    margin-top: 71%;
  }


  
  .titine-filtre {
    position: absolute;
    top: 52%;
    width: 40%;
   left:55%;
  
  }
  
  .title-filtre {
    width: 45%;
  position: absolute;
  top: -1%;
  left: 7%;
  
  }
  
  .filtre-text {
   font-size: 14px;
   height: 25vh !important;
   width: 45%;
margin-top: 8%; 
  }
  



  .title-selle {
    position: absolute;
    top: 16%;
  
  
  }
.titine-selle{
  position: absolute;
  top: 96%;
}

  .text-selle {
 width: 30%;
 height: 28vh;
 font-size: 14px;
 position: absolute;
 top: 65%;
  
  }


.page-number-black-four{

  position: absolute;
  top: 84%;
   
}


  .guardian-bell {
    position: absolute;
    left: 1%;
    top: 2%;

  }



  .titine-cloche {
    position: absolute;
    top: 60%;
    left: 9%;
    width: 30%;
  }

  .cloche-text {
    font-size: 14px;
width: 43%;
height: 37vh;
    margin-top: 4px;
    margin-left: 47%;

  }

  .title-plaque {
   position: absolute;
width: 40%;
left: 11%;
   top: 45%;


  }

  .titine-plaque {
  width: 30%;
  position: 
  absolute;
  top: 105%;
  left: 62%;
  }

  .plaque-text {
  width: 53%;
  font-size: 14px;
  height: 32vh;
  position: absolute;
  top: 47%;
  left: 1%;
  

  }
 

  .page-number-white-plaque {

    position: absolute;
    top: 87%;
    left: 51%;
  }
}

@media screen and (min-width: 1929px) and (max-width: 2879px) {

.titine-book{
  height: 75vh;
  width: 90%;
}

.date-picture{

  margin-left: -12%;
}

.page-number-black-six{
  margin-top: -1px;
}

.page-number-white-five{
 margin-bottom: -19px;
}
.picturebook{
height:46vh;
width: 10%;
}

.page-number-one{
position: absolute;
top: 97% !important;

}

.picturename{
margin-left: -135px;
margin-bottom: 100px;
}
.page-number-one{
position: absolute;
top: 790px;
}

.title-echappement{

position: absolute;
top: 49%;
}

.echappement-description{

  margin-top: 10% !important;
}
.text-key{
height: 18vh;
  width: 45%;
}

.titineechappement{

  margin-top: -35% !important;
}

.titine-guidon{

margin-top: -20px;
  width: 48vh;

}


.text-guidon{
  height: 20vh;
  width: 30%;
  position: absolute;
  top: 89%;
}

.text-selle{

margin-top: 20px;
}

.titine-selle{
  margin-top: -310px;
}

.title-filtre{
 margin-bottom: 31px;
}


.titine-plaque{
width: 40%;
  height: 36vh;
position: absolute;
top: 93%;
}

.plaque-text{
height: 25vh;
  margin-top: 6%;
}

.titinecompteur{

  margin-top: -6%;
}
.title-plaque{

margin-right: 10% !important;
margin-top: -50%;

}
.titine-cloche{
width: 33%;
height: 31vh;
margin-top: -380px;
margin-left: 22px;
}

.page-number-white-plaque{


  position: absolute;
  top: 96%;
}


}


@media only screen and (max-width: 1670px) {

  .titine-book{
    height: 70vh;
    width: 70%;
  }
  
  .date-picture{
  
    margin-left: 9%;
  }
  
  .page-number-black-six{
    margin-top: -17px;
  }
  
  .picturebook{
  height:47%;
  width: 77% !important;
  position: absolute;
  top: 42%;
  }
  
  .picturename {
    margin-left: 56px;
 
    height: 3vh;
    
   
  }
  
  .page-number-one{
  position: absolute;
  top:665px;
  }
  
  .title-echappement{
  
  position: absolute;
  top: 49%;
  }
  
  .text-key{
  margin-top: -35%;
    width: 41%;
  }
  
  .titine-guidon{
  
  margin-top: 80px;
    width: 48vh;
  
  }

  .title-guidon{

position: absolute;
top: 45%;
  }
  
  .titinecompteur{

    height: 38vh;
  }
  
  .text-guidon{
  
    height: 20vh;
  }
  
  .text-selle{
  position: absolute;
  top: 70%;

 }
  
  .title-selle{


   position: absolute;
   top: 34%;
  }
  .titine-selle{
    position: absolute;
    top: 97%;
  }
  
  .title-filtre{
   margin-bottom: 31px;
  }
  
  .titine-plaque{
  width: 40%;
    height: 36vh;
  position: absolute;
  top: 98%;
  }
  
  
  .titine-cloche{
  width: 33%;
  height: 31vh;
  margin-top: -270px;
  margin-left: 22px;
  }
  
  .plaque-text{
width: 48%;
    margin-top: -3px;


}

.page-number-white-five{


  margin-top: -10px;
}
.page-number-white-plaque{

  margin-top: -10px;
}


}




@media (max-width: 480px) {

  body {
    height: 100%;
    margin: 0;
  }
  
  
  .demopage {
    overflow: hidden;
    border-radius: 15px;
    border: 3px solid #DFDFDF;
    height: 83vh;
    position: relative;
  
  
  
  }
  
  
  .white-side,
  .black-side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 39%;
    padding: 20px;
    position: absolute;
    background-color: rgb(0, 0, 0);
  
  }
  
  .white-side {
    text-align: center;
    line-height: 1.6;
    font-size: 1.0em;
    background-color: #DFDFDF;
    width: 50%;
    height: 96%;
  
    .border-black {
  
      margin-left: -19%;
      margin-right: 5%;
      border: 0.1em solid #000000;
      border-radius: 10px;
      padding: 8px;
      height: 52VH;
      width: 80%;
      font-size: 11px;
    }
  
    .picturename {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-top: 2px;
      
      text-align: start;
  
  
    }
  
  
   
    .picturebook {
      border: 0.1em solid #000000;
      margin-top: 180px;
      margin-left: -18%;
      width: 75% ;
      height: 17vh ;
  
    }
  
    .page-number-one {
     position: absolute;
      top: 93%;
      left: 33%;
    }
  }
  
  .number-two {
  
    margin-bottom: 20px;
  }
  
  .black-side {
    right: 0;
    color: #DFDFDF;
    height: 100%;
  
  
    .titinecompteur {
      width: 37%;
      margin-right: 50%;
      border-radius: 2%;
      border: 0.1em solid #DFDFDF;
  
    }
  
   
  
    .titineechappement {
      width: 56%;
      height: 25vh;
      margin-left: 41%;
      margin-top: -259px;
      border-radius: 10px;
      border: 0.1em solid #DFDFDF;
     
      opacity: 0;


  
  
    }
   .kmbook {
  text-align: center;
    width: 100%;
      left: -1%;
      position: absolute;
      top: 1%;
    
    }

    .titinecompteur{
width: 90%;
height: 25vh;
left: 4%;
position: absolute;
top: 14%;


    }
    .km-desription {
    
      position: absolute;
    
      top: 52%;
      margin-left: 4%;
      line-height: 1.6;
      font-size: 1.0em;
      /* Utiliser des unités relatives */
      border-radius: 10px;
      border: 0.1em solid #DFDFDF;
      margin-right: 4%;
      font-size: 10px;
      height: 30%;
      padding: 16px;
      display: flex;
      align-items: center;
      text-align: center;
  width: 68%;
  
    }
  
    .title-echappement {
      margin-left: 44%;
      margin-top: 52px;
      opacity: 0;
    }
  
    .echappement-description {
      width: 31%;
      margin-left: -57%;
      margin-top: 1%;
      line-height: 1.6;
      font-size: 1.0em;
      border-radius: 10px;
      border: 0.1em solid #DFDFDF;
      padding: 14px;
      display: flex;
      align-items: center;
      text-align: center;
      opacity: 0;
    }
  
  }
  
  
  
  .picturebook {
    width: 90%;
    border-radius: 10px;
    margin-top: 5%;
  }
  
  .container-cloche {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-left: 20px;
  
  
  
    .guardian-bell {
      width: 100%;
      margin-left: -20%;
      display: flex;
      margin-top: -1%;
  
    }
  
  }
  
  .titine-cloche {
    border-radius: 10px;
    width: 78%;
    height: 25vh;

    border: 0.1em solid #000000;
 
  position: absolute;
    left: -10%;
  top: 59%;
  
  }
  
  .cloche-text {
    position: absolute;
    left: -45%;
    top: 51%;
    border-radius: 10px;
    border: 0.1em solid #000000;
    width: 65%;
    padding: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    line-height: 1.6;
    height: 31vh;
    font-size: 11px;
  }
  
  
  
  .container-plaque {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    margin-right: 11%;
    margin-top: 2%;
    opacity: 0;
  }
  
  .title-plaque {
    margin-right: 7%;
    margin-top: 1%;
    opacity: 0;
  }
  
  .titine-plaque {
    width: 41%;
    border-radius: 10Px;
    margin-right: -1%;
    margin-top: -37%;
    border: 0.1em solid #000000;
    opacity: 0;
  }
  
  .plaque-text {
    margin-right: 43%;
    margin-top: 5%;
    border-radius: 10px;
    border: 0.1em solid #000000;
    padding: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    line-height: 1.2;
    opacity: 0;
  }
  
  .container-filtre {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    margin-right: 10px;
  
  }
  
  .titine-filtre {
    position: absolute;
    width: 90%;
    height: 20vh;
    top: 68%;
  left: 4%;
  }
  
  .title-filtre {
    position: absolute;
   text-align: center;
    top: 1%;
    width: 90%;
   left: 4%;
  
  }
  
  .filtre-text {
    position: absolute;
    top: 15%;
left: 5%;
    border-radius: 10px;
    border: 0.1em solid #DFDFDF;
    padding: 13Px;
    height: 26vh;
    align-items: center;
    display: flex;
    text-align: center;
    width: 65%;
    font-size: 11px;
  }
  
  .title-selle {
    margin-right: 35%;
    margin-top: 167px;
  
    opacity: 0;
  }
  
  .text-selle {
    border-radius: 10px;
    border: 0.1em solid #DFDFDF;
    padding: 10Px;
    align-items: center;
    display: flex;
    text-align: center;
    margin-top: -53px;
    margin-left: 65%;
    height: 20vh;
    margin-right: 15px;
    opacity: 0;
  }
  
  .page-number-white-plaque {
  
   position: absolute;
   top: 96%;
   left: 34%;
  }
  
  .page-number-white-five {
    position: absolute;
    left: 33%;
    top: 96%;
  
  }
  
  .page-number-black-four {
  
    margin-bottom: 22px;
  }
  
  .page-number-black-six {
  
    position: absolute;
  
    top: 621px;
  }
  
  .titine-selle {
    width: 58%;
    margin-top: -31%;
    margin-right: 34%;
    border-radius: 10px;
    border: 0.1em solid #DFDFDF;
    opacity: 0;
  }
  
  
  
  .title-key {
    width: 100%;
    display: flex;
    justify-content: left;
    margin-left: 25%;
    margin-top: -1%;
    opacity: 0;
  }
  
  .titine-key {
    margin-right: 55%;
    border-radius: 10px;
    width: 35%;
    margin-top: -10px;
    border: 0.1em solid #000000;
    opacity: 0;
  
  }
  
  .text-key {
    border-radius: 10px;
    border: 0.1em solid #000000;
    padding: 20Px;
    align-items: center;
    display: flex;
    text-align: center;
    margin-left: 40%;
    margin-top: -32%;
    width: 34vh;
    opacity: 0;
  }
  
  .title-guidon {
    position: absolute;
    left: -45%;
    top: -1%;
    width: 94%;
  }
  
  .titine-guidon {
    position: absolute;
    left: -39%;
    top: 4%;
    height: 18vh;
    width: 78%;
    border: 0.1em solid #000000;
  }
  
  .text-guidon {
    border-radius: 10px;
    border: 0.1em solid #000000;
   
    align-items: center;
    display: flex;
    text-align: center;
    position: absolute;
    left: 3%;
    top: 58%;
    width: 16vh;
    height: 30vh;
    font-size: 11px;
  }
  
  .titine-book {
    position: absolute;
top: 15%; 
    border-radius: 10px;
    width: 92%;
    height: 30vh;
    border-radius: 10px;
    border: 0.1em solid #fafafa;
  }
  
  .date-picture {
    position: absolute;
    left: -5%;
    top: 50%;
  }
  
  .title-book-titine {
    font-size: 149%;
position: absolute;
top: 3%;  }
  
  .buttonicon {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  
  
  .buttonicon.left {
    left: 10px;
    background-color: #000000;
    color: #DFDFDF;
    cursor: pointer !important;
  
  }
  
  .buttonicon.right {
    right: 10px;
    background-color: #DFDFDF;
    color: #000000;
    cursor: pointer !important;
  
  
  }
  
  .icon {
    height: 30px;
    margin: 0;
  
  }
  
  
  
  
  .page-number-white,
  .page-number-black {
    position: absolute;
    bottom: 20px;
    font-size: 14px;
  
  }
  
  .page-number-white {
    color: black;
  }
  
  .page-number-black {
    color: #DFDFDF;
  }
  .page-number-black-six{

    position: absolute;
    top: 93%;



  }


}
  @media (min-width: 1441px) and (max-width: 1600px) {

.picturebook{

  height: 42vh;
}
  
  
.picturename{


margin-left: 69px;
font-size: 15Px;

}

.page-number-one{


position: absolute;
top: 92%;}


.title-echappement{

  position: absolute;
  top: 45%;
}

.page-number-white-plaque{

  position: absolute;
  top: 96%;
}


.title-selle{

  position: absolute;
  top: 30%;

  
}

.page-number-black-six{

  position: absolute;
  top: 653px;
}

.date-picture{

  position: absolute;
  top: 89%;
}

.titine-book{
  position: absolute;
  top: 16%;
}
  }

/* iPhone 11, iPhone 12, iPhone 13 (tous avec une largeur de 414px en mode portrait) */
@media only screen and (min-device-width: 414px) and (max-device-width: 414px) and (-webkit-device-pixel-ratio: 2) {
  .picturebook{

  height: 20vh;
  }


  .page-number-black-six{

    position: absolute;
    top: 93%;



  }


  .title-guidon{
margin-left: 40%;
    width: 90%;
  }
}

/* iPhone 15 Pro (largeur de 393px en mode portrait) */
@media only screen and (min-device-width: 393px) and (-webkit-device-pixel-ratio: 3) {
  .title-guidon{

    width: 99%;
  }
}


@media only screen and (min-width: 768px) and (max-width: 1359px){
  .titine-book{
   width: 85%;
  }

.date-picture{
  margin-left: 1%;
  margin-top: 1%;
}

.page-number-black-six{
  margin-top: 5%;
}


.title-guidon{
  display: none;
}

.titine-guidon{
  display: none;
}

.text-guidon{
display: none;
}




.text-key{
position: absolute;
top: 89%;
width: 75%;
left: -35%;
}

.title-key{
  position: absolute;
  top: 5%;
  left: 5%;
}

.titine-key{
  position: absolute;
  top: 12%;
left: 20%;
width: 52%;
}

.page-number-white-five{
  position: absolute;
  top: 96%;

}

.title-selle{

  margin-top: 38%;
}
.titine-selle{

margin-top: -35%;
}

.text-selle{
margin-top: -18%;
height: 32vh;
}

.filtre-text{
width: 45%;
height: 30Vh;
}

.title-filtre{
position: absolute;
left: 52%;
width: 100%;
top: 1%;
}

.titine-filtre{
margin-top: -45%;
width: 45%;
}

.titineechappement{

  display: none;
}

.echappement-description{
  display: none;

}

.title-echappement{
  display: none;
}

.titinecompteur{
  width: 60%;
height: 45vh;
  margin-top: -2%;
}

.km-desription{
text-align: center;
margin-top:4%;
border-radius: 1%;
border: 0.1em solid #DFDFDF;
padding:21px;
height: 20vh;
}

.border-black{
margin-top: -3%;
  width: 90%;
  margin-left: -10%;
  border-radius: 3%;
  border: 0.1em solid #000000;
  padding: 2%;
  height: 40vh;
}.text-intro{

  margin-top: -6%;
}


.picturebook{
height: 38vh;
  width: 80% !important;
  position: absolute;
  top: 45%;
  left: 6%;
}
.picturename{
  margin-left: -70%;
  text-align: start;
}

.page-number-one{
  position: absolute;
  top: 92%;
}


.guardian-bell{
  display: none;
}

.cloche-text{
  display: none;
}

.container-{
  display: none;
}


.plaque-text{
position: absolute;
top: 55%;
width: 80%;
left: 2%;
}


.title-plaque{
  position: absolute;
top: 1%;
left: 26%;
}

.titine-plaque{
  position: absolute;
  top: 38%;
height: 40vh;
width: 60%;
left: 17%;
}
    }
  
    @media only screen and (max-width: 1920px) and (max-resolution: 1dppx) {


      .page-number-one{
      position: absolute;
      top: 780px;
      }

      .picturename{
        margin-left: -12%;
        font-size: 13px;
      }

      .title-echappement{
        position: absolute;
   top: 49%;
      }
      
      .titinecompteur{
        margin-top: -1%;
      }



      .titine-cloche{
      position: absolute;
      top: 53%;
      }

      .guardian-bell{
        position: absolute;
        left: 6%;
      }
     .cloche-text{

      margin-top: 40px;
    
     }

   .titine-plaque{
    margin-top: -35%;
   }

   .page-number-white-plaque{

    position: absolute;
    top: 775px;
   }

   .title-selle{
   margin-top: 10px;
   }


   .title-filtre{

    margin-top: 40px;
   }

   .titine-filtre{
    margin-top: -265px;
   }


   .titine-guidon{
margin-top: 10%;
    width: 50%;
   }

   .title-guidon{
position: absolute;
top: 45%;   }
   .page-number-white-five{
  margin-top: 5px;
   }

   .titine-book{
    width: 70%;
   }

   .date-picture{

    margin-left: 8%;
   }

   .page-number-black-six{

    margin-top: 1%;
   }
    }