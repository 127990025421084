body {
  background-color: #000000;
}

.PhotoHome {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.container {
  position: relative;
}

.centered-text {
  position: absolute;
  top: 18%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #000000;
  backdrop-filter: blur(2px);
  border-radius: 5px;
  font-size: 35px;
  font-family: "Enchanted Tales";
  
}

.buttonhome {
  width: 273px;
  height: 58px;
  border-radius: 8px;
  color: #DFDFDF;
  background-color: #000000;
  border: none;
  position: absolute;
  top: 37%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  font-family: "Enchanted Tales";
  font-weight: bold;
  padding: 40px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.buttonhome:hover {
  color: #000000;
  cursor: pointer;
  background-color: transparent;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.9);
  transform: translate(-50%, -50%) translateY(5px);
  font-size: 23px;
}

.container-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 8% 5% 0 5%;
}

.card-text,
.card-text-two {
  flex: 1 1 40%;
  background-color: #DFDFDF;
  padding: 5%;
  word-spacing: 4px;
  line-height: 1.8;
  margin: 2% auto;
  width: 100%;
  box-sizing: border-box;
}
.card-text-three{
  flex: 1 1 40%;
  background-color: #DFDFDF;
  padding: 5%;
  word-spacing: 4px;
  line-height: 1.8;
  margin: 2% auto;
  width: 50%;
  box-sizing: border-box;

}
.titinedes{
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
color: #DFDFDF;
margin-top: 7%;
}

.card-text-two {
  height: 133vh;
}

.movie {
  flex: 1 1 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}



.movie video {
  width: 100%;
  max-width: 750px;
  cursor: pointer;
  margin: 20px 0;
  margin-right: 50px;
}

.movie video::-webkit-media-controls {
  display: none !important;
}
.titinebancp {
  width: 75%; /* Utiliser toute la largeur du conteneur parent */
  height: auto; /* Maintenir le ratio d'aspect */
  margin: auto; /* Centrer la vidéo horizontalement */
  display: block; /* S'assurer que la vidéo est un élément de bloc */
  border-radius: 10px; /* Ajouter des coins arrondis, si désiré */
}


.caintainerbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 3%;
}

.titinesong {
  background-color: #DFDFDF;
  border: none;
  border-radius: 5px;
  color: #000000;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  padding: 25px;
  width: 278px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title,
.titre,
.titlenews,
.titinetwo,
.titretwo,
.titlebanc {
  display: flex;
  justify-content: center;
  color: #DFDFDF;
  font-family: "Enchanted Tales";
  margin-top: 10%;
  text-align: center;
}




.description-and-pictures {
  display: flex;
  width: 83%;
  height: auto;
  flex-wrap: wrap;
  margin: 0 auto;
}

.Pieces img {
  max-width: 100%;
  height: auto;
  margin-top: 29px;
  margin-right: 20px;
  width: 660px;
}

.critair {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7a701;
  width: 35px;
  height: 35px;
  color: #fff;
  border-radius: 100%;
  margin-top: -8px;
}

.titinebanc,
.moteur,
.boite,
.reservoir {
  width: 100%;
  max-width: 640px;
  height: auto;
  border-radius: 5px;
  margin: 3% auto;
  display: block;
}

.photolove  {
  width: 47%;
  margin: -4% auto;
  display: block;
  border-radius: 5px;
}

.titinepuissance {
  width: 273px;
  height: 88px;
  border-radius: 5px;
  color: #000000;
  background-color: #DFDFDF;
  border: none;
  display: block;
  margin: 3% auto;
  text-align: center;
  cursor: pointer;
  font-size: 21px;
  font-weight: bold;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #DFDFDF;
  color: #000000;
  border-radius: 5px;
  height: 45px;
  margin-top: 10%;
}

.dev {
  color: #000000;
  left: 1%;
  position: absolute;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
}

.dev a {
  color: #000000;
}

.contact svg {
  color: #000000;
  height: 40px;
}

.topp {
  position: absolute;
  right: 1%;
  border: none;
  cursor: pointer;
  background-color: #DFDFDF;
  color: #000000;
  border-radius: 5px;
  padding: 12px;
  font-weight: bold;
  font-size: 15px;
  transition: all 0.3s ease;
  margin-left: 3%;
}

.topp:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.9);
  transform: translateY(-2px);

}

.chevronup {
  margin-left: 10px;
}

.reseau {
  margin-right: 5px;
}
.buttonbook {
  cursor: pointer;
  margin-left: 85%;
  padding: 10px 40px;
  border-radius: 10px;
  text-decoration: none;
  background-color: #3498DB;
  border-bottom: 5px solid #2980B9;
  text-shadow: 0px -2px #2980B9;
  transition: all 0.1s;
  -webkit-transition: all 0.1s;
  display: inline-block;
  color: #fff;
  font-size: 16px;
  animation: jump 1s infinite, blink-bg 1s infinite;
}


/* Animation for the whole button jump */
@keyframes jump {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

/* Animation for the background blinking */
@keyframes blink-bg {
  0% {
    background-color: #3498DB;
  }
  50% {
    background-color: #fff;
  }
  100% {
    background-color: #3498DB;
  }
}

/* Animation for each letter jumping and blinking */
.buttonbook span {
  display: inline-block;
  animation: jump-letter 1s infinite, blink-text 1s infinite;
}

@keyframes jump-letter {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes blink-text {
  0% {
    color: #fff;
  }
  50% {
    color: #000;
  }
  100% {
    color: #fff;
  }
}



@media (max-width: 768px) {
  header {
    flex-direction: column;
    height: auto;
    font-size: 17px;
  }

  .slogan {
    margin-left: 0;
    margin-bottom: 10px;
  }

  .page {
    justify-content: center;
    margin-right: 0;
  }

  .buttonhome {
    font-size: 18px;
    padding: 20px;
  }

  .card-text,
  .card-text-three,
  .card-text-two {
    flex: 1 1 100%;
  }

  .titinepuissance {
    width: 80%;
  }

  

}








 @media screen and (min-width: 2020px) and (max-width: 2879px) {




  .centered-text{
font-size: 44px;

  }
 

  .buttonhome{
height: 9vh;
    width: 15%;
    font-size: 24px;
  }

  .card-text{
    margin-left: 9%;
  }

  .movie{
  

    margin-right: 6%;
  }


  .card-text-two{

    height: 105vh;
  }
 }





 @media only screen and (max-width: 1670px) {
  .centered-text {
    
  
    font-size: 30px;
  }
  
  .card-text{

padding: 3%;
    height: 50vh;
  }

  .card-text-two{

    height: 160vh;
  }


}


@media screen and (min-width: 1280px) and (max-width: 1366px) {

  .centered-text {
    margin-top: 5%;
      width: 100%;
      height: 40vh;
    font-size: 39px;
    
    color: rgb(0, 0, 0);
      }
    
      .buttonhome {
    margin-top: 25px;
    width: 20%;
    height: -10vh;
    padding: 35px;
    
      }
      
    .card-text{
      height: 36vh;
     padding: 45px;
      margin-left: -19px;
    
      h1{
    
        margin-top: -20px;
      }
    }
    
    .movie video {
      width: 105%;
     margin-right: 50px;
    
     
    }
    
    
    .description-and-pictures {
    
      width: 83%;
      
    }
    
    .Pieces img{
      max-width: 100%; 
     
      margin-top: 29px;
      margin-right: 20px;
      width: 510px ;
      height: 362px;
    }
    
    .card-text-two{
      
    
      height: 117vh;
    
      h1{
        margin-top: -40px;
      }
     
    }
    
    .movie {
      flex: 1 1 40%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    
    
    
    .titinebancp {
      width: 70% ; /* Utiliser toute la largeur du conteneur parent */
      border-radius: 5px; /* Ajouter des coins arrondis, si désiré */
    }
    
    .titinepuissance {
      width: 263px;
      height: 75px;
    
    }
    
    .card-text-three{
    
    height: 65vh;
    width: 60%;
    
    }
    img.photolove  {
      width: 55%;
      height: 50vh;
    }
     
    
    footer {
    
      margin-top: 15%;
    }
    
    
     }

@media (max-width: 480px) {
  header {
    flex-direction: column;
   
    font-size: 17px;
  }

  .page p{
margin-left: 6px !important;
   font-size: 15px;
  
  }
  .slogan {
   width: 100%;
   margin-top: 100px;
  }

 
  .buttonhome {
    font-size: 17px;
    padding: 34px;
    width:65%;
    margin-top: 10px;
   
   
  }

  .description-and-pictures {
    flex-direction: column;
    
  }

  .movie video {
    width: 100%;
  }


  .titinesong{
width: 75%;

  }

  .critair{

  margin-left: 43%;
  }
  .titinepuissance {
    width: 90%;
  }
  .centered-text {
   width: 100%;
font-size: 20px;


  
  }
  .titinepuissance{
height: 10vh;
  }

  .buttonhome:hover {
    
    font-size: 18px;
  }
  .card-text{
height: 68vh !important;
  text-align: center;
  
  }
  .movie video {
 margin-left: 49px;
    margin-top: -20px;
    width: 95%;
    
  }

  .titinedes {
    margin-top: 19%;
  }
  
  .moteur,
  .boite,
  .reservoir {
    margin: 1px auto; /* Réduit l'espace entre les photos */
    
  }
  
  .Pieces img {
    margin-top: 8px;
 
  }
  
  .card-text-two {
    text-align: center;
  }

  .titlebanc{

    margin-top: 30%;
  }

  .titinebancp {
    width: 100%; /* Utiliser toute la largeur du conteneur parent */
    margin-top: 40px;
  }
  
.titinepuissance{
  margin-top: 40px;
width: 70%;


}
.titlenews{
  margin-top: 90px;
}

.card-text-three{
width: 91%;
height: 136vh;
text-align: center;

}

img.photolove{
  margin-top: -35px;
height: 25vh;
  width: 87%;
}
footer {

  margin-top: 20%;
}



.topp:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.9);
  transform: translateY(-2px);
}

.topp{

width:32%;
height: 45px;}



}




/* Écrans larges (petits ordinateurs de bureau) */
@media (min-width: 1025px) and (max-width: 1280px) {
  .centered-text{
    width: 100%;

  
  }
}



/* Écrans ultra larges */
@media (min-width: 1441px) and (max-width: 1600px) {
.card-text-two{

height: 171vh;
}
}


@media only screen and (min-device-width: 414px) and (max-device-width: 414px) and (-webkit-device-pixel-ratio: 2) {

  .centered-text{


    font-size: 24px;
  }


  .movie{
    
    margin-top: -80px;}


 .card-text-three{


  height: 118vh;
 }
}



/*iphone pro max*/

@media only screen and (min-device-width: 393px) and (-webkit-device-pixel-ratio: 3) {

  .centered-text{
    
    font-size: 25Px;
  }

  .card-text{
    height: 55vh !important;
  }


  .card-text-three{

    height: 110vh;
  }
}



/*ipad normal*/
@media only screen and (min-width: 768px) and (max-width: 1359px){

.centered-text{
width: 100%;
margin-top: 1%;
  font-size: 190%;
}

.card-text{

  height: 55VH;
}

.description-and-pictures img{


  width: 100%;

}


.card-text-two{

  height: 163vh;
}

.critair{
margin-left: 8%;
  margin-top: -6%;
}


.card-text-three{

width: 60%;
  height: 99vh;
}

.photolove{
  height: 55vh;
  width: 64%;
}


}



@media only screen and (max-width: 1920px) and (max-resolution: 1dppx) {

.card-text-two{
  height: 137vh;
}

.card-text{
  margin-left: 90px;
}

.movie{

margin-right: 90px;
}
}

