body {
    overflow-x: hidden;
}

.buttonsong-container {
    display: flex;
    justify-content: center;
}

.buttonsong {
    background-color: #DFDFDF;
    border: none;
    border-radius: 5px;
    color: #000000;
    margin-top: 3%;
    width: 273px;
        height: 72px;
        padding: 25px;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: row;
    cursor: pointer;
    font-family: "Enchanted Tales";
    font-size: 21px;
    font-weight: bold;



}


.titrelove {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #DFDFDF;
    margin-top: 4%;
    font-family: "Enchanted Tales";


}

.card-text-date {
    width: 40%;
    background-color: #DFDFDF;
    margin-top: 4%;
    padding: 5%;
    margin-right: 50%;
    word-spacing: 4px;
    line-height: 1.8;
    margin-left: 5%;


}

.titinelove {

    height: 42vh;
    width: 45%;
    margin-left: 74%;
    word-spacing: 4px;
    line-height: 1.8;
    left: 1%;
    top: 67%;
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 2;


}



.titre-couple {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #DFDFDF;
    margin-top: 15%;
    font-family: "Enchanted Tales";


}

.card-couple {

    width: 40%;
    background-color: #DFDFDF;
    margin-top: 4%;
    padding: 5%;
    word-spacing: 4px;
    line-height: 1.8;
    position: absolute;
    left: 46%;
    z-index: 1;
    top: 120%;
}

.titine-couple {

    height: 53vh;
    width: 40%;
    word-spacing: 4px;
    line-height: 1.8;
    position: absolute;
    top: 124%;
    left: 7%;
    border-radius: 1%;
    z-index: 2;


}


.titrelovetwo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #DFDFDF;
    word-spacing: 4px;
    line-height: 1.8;
    position: absolute;
    top: 206%;
    width: 100%;
    text-align: center;
    font-family: "Enchanted Tales";



}



.Titine-aventure {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #DFDFDF;
    word-spacing: 4px;
    line-height: 1.8;
    position: absolute;
    top: 386%;
    width: 100%;
    text-align: center;
    margin-top: 80px;
    font-family: "Enchanted Tales";

}

.titre-saint-valentin {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #DFDFDF;
    word-spacing: 4px;
    line-height: 1.8;
    position: absolute;
    top: 336%;
    width: 100%;
    text-align: center;
    font-family: "Enchanted Tales";

}

.card-saint-valentin {
    width: 40%;
    background-color: #DFDFDF;
    margin-top: 4%;
    padding: 6%;
    word-spacing: 4px;
    line-height: 1.8;
    position: absolute;
    left: 5%;
    top: 345%;
    z-index: 1;
}

.titine-saint-valentin {

    height: 52vh;
    width: 43%;
    position: absolute;
    left: 53%;
    top: 349%;
    z-index: 2;
}




.titre-mariage {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #DFDFDF;
    word-spacing: 4px;
    line-height: 1.8;
    position: absolute;
    top: 436%;
    font-family: "Enchanted Tales";

    width: 100%;
    text-align: center;
}


.card-mariage {

    width: 40%;
    background-color: #DFDFDF;
    margin-top: 4%;
    padding: 5%;
    word-spacing: 4px;
    line-height: 1.8;
    position: absolute;
    left: 46%;
    top: 443%;
    z-index: 1;


}


.photo-mariage {
    height: 55vh;
    width: 39%;
    position: absolute;
    left: 8%;
    top: 448%;
    z-index: 2;


}


.Titine-aventure {

    top: 205%;
}

.video-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 85%;
    position: absolute;
    top: 226%;
    width: 100%;
}

.video-wrapper {
    position: relative;
    width: calc(100% / 3 - 20px);
    height: 100%;
    overflow: hidden;

}

.play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    color: #DFDFDF;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    font-size: 70px;
}
.video-aventure,
.video-aventure-deux,
.video-aventure-trois {
    width: 100%;
    height: 100%;

}

.video-aventure,
.video-aventure-deux {
    margin-right: 10px;
}

.video-aventure-trois {
    margin-left: 10px;
}


.titre-calin {
    display: flex;
    justify-content: center;
    color: #DFDFDF;
    position: absolute;
    top: 533%;
    font-family: "Enchanted Tales";

}

.titine-calin {

    width: 36%;
    position: absolute;
    top: 541%;
    padding: 16px;

}

.image-container {

    display: flex;
    justify-content: center;

}

.image-container-two {
    display: flex;
    justify-content: center;
    /* Centre les images horizontalement */
    align-items: center;
    /* Centre les images verticalement */
}

.titine-main {
    width: 33%;
    top: 682%;
    position: absolute;
    right: 10%;
    height: 85%;
    font-family: "Enchanted Tales";

}

.titine-km {
    width: 33%;
    top: 682%;
    position: absolute;
    left: 10%;
   

}


.km-amour {
    color: #DFDFDF;
    top: 670%;
    position: absolute;
    left: 16%;
    line-height: 1.2;
    font-size: 25px;



}



.amour-chemin {
    color: #DFDFDF;
    top: 667%;
    position: absolute;
    right: 15%;
    font-size: 25px;
    line-height: 1.2;
    text-align: center;
}

.titre-love {
    font-family: "Enchanted Tales";

    top: 655%;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: aliceblue;
    border-radius: 5px;
    height: 45px;

}


.footertwo {

    top: 1237%;
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #DFDFDF;
    color: aliceblue;
    border-radius: 5px;
    height: 45px;

}

.contact svg {
    color: #000000;
    height: 40px;

}



.slide-element span {
    opacity: 0;
    transition: opacity 3.0s ease-in-out;
    
}

.slide-element span.show {
    opacity: 1;

}

.slide-element {
    opacity: 0;
    transition: transform 3s ease, opacity 1s ease;

}

.slide-from-right {
    transform: translateX(100%);

}

.slide-from-left {
    transform: translateX(-100%);
}

.slide-element.show {
    opacity: 1;
    transform: translateX(0);

}







.Titine-parole {

    color: #000000;
    top: 823%;
    position: absolute;
    font-style: italic;

    line-height: 1.3;
    font-size: 25px;
    background-color: #dfdfdf;
    font-family: "Enchanted Tales";
    border-radius: 15px;
    padding: 2%;
    text-align: center;
    right: 55%;
    height: 75%;

}

.Container-parole {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;


}

.titine-parole-two {
    color: #000000;
    top: 823%;
    position: absolute;
    left: 55%;
    line-height: 1.3;
    font-size: 25px;
    background-color: #dfdfdf;
    font-family: "Enchanted Tales";
    border-radius: 15px;
    padding: 2%;
    height: 75%;
    text-align: center;
    font-style: italic;

}
.number-two-indien {
    margin-top: 31px;

}

.Onira {
    top: 810%;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #dfdfdf;
    border-radius: 5px;
    height: 45px;
    font-family: "Enchanted Tales";


}



.dev {

    color: #000000;
    left: 1%;
    position: absolute;
    cursor: pointer;
    font-size: 12px;

font-weight: bold;
    a {
        color: #000000;

    }
}

.top {
    right: 1%;
    position: absolute;
    cursor: pointer;
    background-color: #DFDFDF;
    color: #000000;
    border-radius: 5px;
    padding: 12px;
    font-weight: bold;
    transition: all 0.3s ease;
    
  margin-left: 3%;
    }
    
    .top:hover {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 9.9);
        transform: translateY(-2px);
    
    }
    
    
    .container-forever {
        font-family: "Enchanted Tales";
        width: 100%;
        top: 1150%;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: aliceblue;
    
        .titineforever {
            margin-top: 2%;
            height: 800px;
        }
    }
    
    .hearts-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        /* Pour que les cœurs n'interfèrent pas avec les interactions de la page */
        overflow: hidden;
}
.heart {
    position: absolute;
    font-size: 24px;
    color: red;
    animation: float 400s linear infinite;
    opacity: 0;
}

.container-bob {
    top: 1050%;
    position: absolute;
    color: #DFDFDF;
    text-align: center;
    font-family: "Enchanted Tales";


}

.merch {
    top: 918%;
    position: absolute;
    color: #DFDFDF;
    text-align: center;
    width: 100%;
    font-size: 300%;
    font-family: "Enchanted Tales";


}
@keyframes float {
    0% {
        transform: translateY(110vh);
        opacity: 1;
    }

    100% {
        transform: translateY(-900vh);
        opacity: 0;
    }
}




.container-tshirt {
    text-align: center;
    top: 935%;
    position: absolute;
    color: #DFDFDF;
   

  }

  
  
  .image-containerfour {
    display: flex;
    justify-content: center;
    gap: 50px; /* Espace entre les images, ajustez selon vos besoins */
    margin-top: 4%;
  }
  
  .image-containerfour img {
    width: 35%; /* Définissez une largeur fixe pour les images */
    height: auto; /* Conservez le ratio d'aspect */
    border-radius: 10px;
  }
  
/* Ipad pro 13*/
@media only screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {

    body {
        overflow-x: hidden;
        max-width: 100%;
        width: 100%;
        /* ceci est la solution au défilement horizontal de titinelove !!!!!!*/


    }


    .titinelove {
        margin-left: 1005Px;
        top: 62% !important;
        width: 48% !important;
        height: 41vh !important;


    }

    .card-text-date {
        width: 41% !important;
        height: 29vh !important;
        background-color: #DFDFDF;
        margin-left: 2%;


    }


    .titre-couple{
        position: absolute;
        top: 75% !important;
        left: 43%;

    }
    .titine-couple {

        height: 42vh !important;
        width: 41% !important;
        word-spacing: 4px;
        line-height: 1.8;
        position: absolute;
        top: 107% !important;
        left: 8%;
        border-radius: 1%;
        z-index: 2;


    }

    .card-couple {
        position: absolute;
        top: 101% !important;
        width: 40% !important;
        height: 29vh !important;
       left: 47%;

    }

    .video-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 85%;
        position: absolute;
        top: 169% !important;
        width: 100%;
    }

    .Titine-aventure {
        position: absolute;
        top: 154% !important;


    }

    .video-aventure-trois {

        width: 89%;
    }

    .titine-saint-valentin {

        height: 40vh !important;
        width: 43% !important;
        position: absolute;
        left: 53%;
        top: 276% !important;
        z-index: 2;
    }

    .card-saint-valentin {
        position: absolute;
        width: 40% !important;
        height: 25vh !important;
        top: 269% !important;

    }

    .titre-saint-valentin {
        position: absolute;
        left: 1% !important;
        top: 259% !important;


    }

    .card-mariage {
        position: absolute;
        width: 40% !important;
        height: 33vh !important;
        top: 339% !important;

    }

    .titre-mariage {

        position: absolute;

        top: 331% !important;


    }

    .photo-mariage {
        height: 45vh !important;
        width: 43% !important;
        position: absolute;
        left: 5% !important;
        top: 346% !important;
        z-index: 2;
    }

    .titre-calin {
        position: absolute;
        top: 411% !important;

    }

    .titine-calin {
        height: 90Vh !important;
        width: 47% !important;
        position: absolute;
        top: 420% !important;
        padding: 16px;
        border-radius: 20px;
    }

    .titine-main {
        position: absolute;
        top: 555% !important;
        height: 80vh !important;

    }

    .titine-km {
        position: absolute;
        top: 555% !important;
        height: 80vh !important;


    }

    .km-amour {
        position: absolute;
        top: 543% !important;
        left: 17% !important;

    }



    .amour-chemin {
        position: absolute;
        top: 540% !important;
    }

    .Titine-parole {
        right: 53%;
    }

    .container-bob {
        top: 660% !important;
        position: absolute;
        color: #DFDFDF;
        text-align: center;
        font-family: "Enchanted Tales";

        img {
            width: 40% !important;

        }

    }

    .merch {
        top: 650% !important;
        position: absolute;
        color: #DFDFDF;
        text-align: center;
        width: 100%;
        font-size: 300%;
        font-family: "Enchanted Tales";


    }


    .container-tshirt {
        top: 755% !important;
        position: absolute;


        img {
            width: 40% !important;

        }
    }


    .Titine-parole {
        top: 875% !important;
        position: absolute;
        height: 72vh !important;

    }

    .number-two-indien {

        margin-top: 31px !important;
    }

    .Onira {

        top: 860% !important;
        position: absolute;


    }

    .titine-parole-two {
        top: 875% !important;
        position: absolute;
        height: 72vh !important;
    }



    .container-forever {
        top: 989% !important;
        position: absolute;

    }

    .slide-from-right {
        transform: translateX(0%);

    }


    .slide-from-left {
        transform: translateX(-70%);
    }

    .footertwo {

        top: 1095% !important;
        position: absolute;


    }
}



@media (max-width: 480px) {

    .titrelove {

        margin-top: 10%;

    }

    .buttonsong {
        margin-top: 9%;
        width: 220px;
        height: 72px;
    }

    .card-text-date {
        width: 83%;
        margin-top: 40px;


    }

    .titinelove {
        margin-left: 181px;
        height: 30vh;
        width: 90%;
        top: 145%;
        position: absolute;
        transform: translate(-50%, -50%);
        z-index: 2;


    }

    .card-couple {
        margin-left: -170Px;
        width: 100%;
        margin-top: 390px;
        height: 50vh;
    }

    .titine-couple {

        height: 35vh;
        width: 100%;
        position: absolute;
        top: 224%;
        left: 4%;

    }



    .Titine-aventure {

        top: 255%;
    }



    @media screen and (min-width: 1890px) and (max-width: 2879px) {

        .titinelove {
            width: 42%;
            height: 44vh;
            margin-top: 2%;
            margin-left: 74%;
        }

        .titre-couple {

            margin-top: 10%;
        }

        .titine-saint-valentin {
            margin-top: 50px;
            height: 48vh;
            width: 40%;
        }


        .photo-mariage {
            margin-top: 15px;
            height: 55vh;

            margin-left: 15px;

        }


        .titine-main {

            height: 97Vh;


        }

        .Titine-parole {

            height: 77vh;
        }

        .titine-parole-two {

            height: 77vh;
        }


        .container-bob {
            margin-top: 8%;
        }

        .container-forever {
            margin-top: 13%;
        }

        .footertwo {



            margin-top: 21%;
        }
    }


    @media only screen and (max-width: 1670px) {

        .titinelove {
            width: 43%;
            height: 54vh;
            margin-top: 6%;
            margin-left: 73%;
        }

        .card-text-date {
            margin-left: 9%;
            width: 35%;
        }

        .titre-couple {

            margin-top: 10%;
        }

        .card-couple {

            margin-top: 10%;
        }

        .titine-couple {
            height: 60vh;
            margin-top: 7%;
        }

        .card-saint-valentin {

            width: 35%;
            height: 28vh;
            margin-left: 25px;
        }

        .titine-saint-valentin {
            margin-top: 20px;
            height: 58vh;
            width: 43%;
        }

        .video-container {

            margin-top: -10%;
        }

        .video-aventure,
        .video-aventure-deux,
        .video-aventure-trois {
            margin-left: 5%;
            height: 100%;
        }

        .Titine-aventure {


            margin-top: -3%;
        }

        .play-icon {
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 50px;
            color: #DFDFDF;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
            font-size: 70px;
        }


        .photo-mariage {
            margin-top: 15px;
            height: 65vh;

            margin-left: 15px;

        }


        .titine-main {

            height: 100Vh;


        }


        .amour-chemin {
            margin-top: -4px;
        }

        .Titine-parole {

            height: 91vh;
        }

        .merch {

            margin-top: 9%;
        }

        .titine-parole-two {

            height: 91vh;
        }


        .container-tshirt {

            margin-top: 6%;
        }

        .container-bob {
            margin-top: 13%;
        }

        .container-forever {
            margin-top: 19%;
        }

        .footertwo {



            margin-top: 35%;
        }
    }


    @media screen and (min-width: 1280px) and (max-width: 1366px) {


        .titinelove {
            width: 48%;
            height: 52vh;
            margin-top: -14%;
            margin-left: 74%;
        }

        .card-text-date {
            margin-left: 4%;
            width: 41%;
        
            height: 45vh;
        }

        .titre-couple {

            margin-top: 20%;
        }

        .card-couple {

            margin-top: 10%;
        }

        .titine-couple {
            height: 60vh;
            margin-top: -11%;
        }

        .card-saint-valentin {
            margin-top: -30%;

            width: 35%;
            height: 28vh;
            margin-left: 25px;
        }

        .titre-saint-valentin {

            margin-top: -30%;
        }

        .titine-saint-valentin {
            margin-top: -38%;
            height: 51vh;
            width: 43%;
        }

        .video-aventure,
        .video-aventure-deux,
        .video-aventure-trois {

            height: 80%;

        }

        .photo-mariage {
            margin-top: 15px;
            height: 65vh;

            margin-left: 15px;

        }


        .titine-main {

            height: 100Vh;


        }


        .amour-chemin {
            margin-top: -4px;
        }

        .Titine-parole {

            height: 91vh;
        }

        .merch {

            margin-top: 9%;
        }

        .titine-parole-two {

            height: 91vh;
        }


        .container-tshirt {

            margin-top: 6%;
        }

        .container-bob {
            margin-top: 13%;
        }

        .container-forever {
            margin-top: 19%;
        }

        .footertwo {



            margin-top: 35%;
        }




    }









    @media (max-width: 480px) {




        .titrelove {
            margin-top: 10%;
        }

        .buttonsong {
            margin-top: 9%;
            width: 220px;
            height: 72px;
        }

        .card-text-date {
            width: 79% !important;
            margin-top: 40px;
            height: 85vh;
            margin-left: 23px;

        }

        .titre-couple {
            display: flex;
            flex-direction: row;
            justify-content: center;
            color: #DFDFDF;
            margin-top: 50%;

        }

        .titinelove {
            margin-left: 50%;
            height: 29vh;
            width: 94%;
            top: 139%;
            position: absolute;
            transform: translate(-50%, -50%);
            z-index: 2;
        }

        .card-couple {
            margin-left: -145px;
            width: 82%;
            margin-top: 429px;
            height: 65vh;
        }

        .titine-couple {
            height: 38vh;
            width: 97%;
            position: absolute;
            top: 241%;
            left: 3%;
        }

        .Titine-aventure {
            top: 288%;
        }

        .video-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 235Vh;
            position: absolute;
            top: 300%;
            width: 100%;
            right: 3%;
        }

        .video-wrapper {
            position: relative;
            width: 100%;
            height: 100vh;
            overflow: hidden;
            margin-bottom: 20px;
        }

        .play-icon {
            position: absolute;
            top: 50%;
            left: 55%;
            transform: translate(-50%, -50%);
            font-size: 80px;
            color: #DFDFDF;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
        }

        .video-aventure-trois {

            height: 85Vh;

        }
    }


    .titre-saint-valentin {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #DFDFDF;
        word-spacing: 4px;
        line-height: 1.8;
        position: absolute;
        top: 544%;
        width: 100%;
        text-align: center;
        font-family: "Enchanted Tales";
        font-size: 29px;
        left: 2%;
    }

    .card-saint-valentin {
        height: 56vh;
        width: 80%;
        position: absolute;
        left: -1%;
        top: 553%;

    }

    .titine-saint-valentin {

        height: 34vh;
        width: 96%;
        position: absolute;
        left: 4%;
        top: 611%;

    }




    .titre-mariage {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #DFDFDF;
        word-spacing: 4px;
        line-height: 1.8;
        position: absolute;
        top: 655%;
        font-family: "Enchanted Tales";
        width: 100%;
        text-align: center;
    }


    .card-mariage {

        height: 86vh;
        width: 80%;
        background-color: #DFDFDF;
        left: 6%;
        position: absolute;
        top: 666%;
        z-index: 1;
    }


    .photo-mariage {
        height: 37vh;
        width: 95%;
        position: absolute;
        left: -1%;
        top: 755%;
        z-index: 2;


    }

    .titre-calin {
        display: flex;
        justify-content: center;
        color: #DFDFDF;
        position: absolute;
        top: 797%;
        font-family: "Enchanted Tales";

    }

    .titine-calin {
        height: 65vh;
        width: 93%;
        position: absolute;
        top: 805%;
        padding: 16px;

    }



    .titine-main {
        width: 88%;
        top: 907%;
        position: absolute;
        left: 6%;
        height: 75%;
        border-radius: 10px;


    }

    .titine-km {
        width: 93%;
        height: 80vh;
        top: 999%;
        position: absolute;
        left: 5%;
        border-radius: 10px;

    }

    .km-amour {
        color: #DFDFDF;
        top: 989%;
        position: absolute;
        left: 7%;
        line-height: 1.2;
        font-size: 25px;



    }

    .amour-chemin {
        color: #DFDFDF;
        top: 880%;
        position: absolute;
        left: 15%;
        font-size: 25px;
        line-height: 1.2;
        text-align: center;
    }




    .Titine-parole {

        color: #000000;
        top: 1110%;
        position: absolute;
        font-style: italic;
        line-height: 1.3;
        font-size: 25px;
        background-color: #dfdfdf;
        font-family: "Enchanted Tales";
        border-radius: 15px;
        padding: 2%;
        text-align: center;
        width: 93%;
        height: 151%;
        left: 2%;
    }

    .Container-parole {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        display: flex;


    }

    .titine-parole-two {
        color: #000000;
        top: 1267%;
        position: absolute;
        left: 1%;

        width: 93%;
        height: 141%;


    }

    .number-two-indien {
        margin-top: 10px;

    }

    .Onira {
        top: 1092%;
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #dfdfdf;
        border-radius: 5px;
        height: 40px;
        left: 14%;
        font-family: "Enchanted Tales";

    }

    .container-tshirt {
        top: 1420%;
        position: absolute;
        color: #DFDFDF;
        display: flex;
        flex-direction: column;
        justify-content: space-between;


    }

    .image-containerfour {
        display: flex;
        flex-direction: column;
        /* Changer la direction de la flexbox pour une colonne */
        align-items: center;
        /* Centre les éléments horizontalement */
        gap: 50px;
        /* Espace entre les images, ajustez selon vos besoins */
        margin-top: 4%;
        width: 100%;
    }

    .image-containerfour img {
        width: 100%;
        border-radius: 10px;
    }

    .container-forever {
        height: 90vh;
        width: 100%;
        top: 1690%;
        position: absolute;
        text-align: center;

        .titineforever {
            height: 48%;
            width: 100%;
        }
    }

    .container-bob {
        top: 1575%;
        position: absolute;

    }

    .merch {


        top: 1410%;
        position: absolute;
    }

    .footertwo {

        top: 1777%;
        position: absolute;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #DFDFDF;
        color: aliceblue;
        border-radius: 5px;
        height: 45px;

    }

    .top {

        width: 27%;
        height: 21px;
        text-align: center;
    }


    .slide-from-right {
        transform: translateX(0%);

    }


    .slide-from-left {
        transform: translateX(-70%);
    }




}






/* Écrans ultra larges */
@media (min-width: 1441px) and (max-width: 1600px) {
    .titinelove {
        margin-top: 7%;
        height: 55vh;
    }

    .titre-couple {
        margin-top: 3%;

    }

    .titine-couple {
        height: 54Vh;
        margin-top: 3%;
    }

    .titine-saint-valentin {
        margin-top: 1%;
        height: 61Vh;
    }

    .photo-mariage {
        margin-top: 2%;
        height: 63vh;
    }


    .amour-chemin {
        margin-top: 1px;
    }

    .titine-main {


        height: 100vh;
    }


    .titine-parole-two {

        height: 96Vh;
    }

    .Titine-parole {

        height: 96Vh;

    }


    .merch {

        margin-top: 10%;
    }


    .container-tshirt {

        margin-top: 6%;

    }

    .container-bob {


        margin-top: 14%;
    }

    .container-forever {

        margin-top: 17%;

    }


    .footertwo {
        margin-top: 37%;



    }
}

@media(min-width: 1865px) and (max-width: 1920px) {


    .titinelove {

        margin-top: 40px;
        height: 48vh;


    }

    .titre-couple {


        margin-top: 9%;
    }


    .titine-couple {


        height: 55%;
    }

    .titine-saint-valentin {
        margin-top: 1%;
        height: 55vh;


    }


    .photo-mariage {
        margin-top: 1%;


    }

    .titine-main {

        height: 96vh;
    }


    .km-amour {

        left: 17%;
    }

    .Titine-parole {

        height: 77vh;

    }

    .titine-parole-two {

        height: 77vh;


    }

    .container-bob {


        margin-top: 7%;
    }


    .container-forever {

        margin-top: 9%;
    }


    .footertwo {

        margin-top: 18%;
    }

}





/* iPhone 11, iPhone 12, iPhone 13 (tous avec une largeur de 414px en mode portrait) */
@media only screen and (min-device-width: 414px) and (max-device-width: 414px) and (-webkit-device-pixel-ratio: 2) {
  
    .titre-couple{

        margin-top: 29%;
    }

    .card-couple{

        left: 40%;
    }

    .Titine-aventure{

        margin-top: 23%;
    }

    .video-aventure{

        margin-top: 40%;
    }


    .amour-chemin{
margin-top: 5%;
       
    }
  

  .container-bob{

    margin-top: 70%;
  }


  .container-forever{


    margin-top: 120%;
  }

  .footertwo{


    margin-top: 140%;
  }

}
  
  /* iPhone 15 Pro (largeur de 393px en mode portrait) */
  @media only screen and (min-device-width: 393px) and (-webkit-device-pixel-ratio: 3) {
.titinelove{

    margin-top: -5%;
}


.titre-couple{

    margin-top: 29%;
}

.card-couple{
    margin-left: -42%;
}
.titine-couple{

    margin-top: -3%;
}

.video-container{

    margin-top: -9%;
}
.Titine-aventure{

    margin-top: -6%;
}

 .card-saint-valentin{

    margin-top: 25%;
 }

 .titre-saint-valentin{

    margin-top: 24%;

 }

 .titine-saint-valentin{
margin-top: 20%;
height: 36vh;
width:95%;
}


.card-mariage{

margin-top: 20%;
height: 68vh;
}

.titre-mariage{

    margin-top: 23%;
}


.amour-chemin{

    margin-top: 8%;
}

.km-amour{

    margin-left: 7%;
}

.Onira{
    text-align: center;
  margin-left: -11%;
width: 95%;
margin-top: 22%;
}


.Titine-parole{
margin-top: 15%;
height: 124vh;
}


.titine-parole-two{

height: 109vh;
}

.merch{

    margin-top: -10%;
}

.container-tshirt{
    margin-top: -12%;
}


.container-bob{

margin-top: 39%;
}

.container-forever{


    margin-top: 80%;
}


.footertwo{

    margin-top: 93%;
}
  }

  
  
  @media screen and (min-width: 2020px) and (max-width: 2879px) {



    .titinelove{

      margin-top: -5%;  
    }


.titine-couple{
margin-top: -5%;
    height: 48vh;
}

.card-couple{
height: 25vh;
    margin-top: -1%;
}


.card-saint-valentin{

    height: 25vh;
}

.card-mariage{
height: 28vh;
    margin-top: 6%;
}

.titine-main{
    height: 80vh;
}

.Titine-parole{

height: 60vh;
  }

  .titine-parole-two{

height: 60vh;
  }

}


@media only screen and (min-width: 768px) and (max-width: 1359px){


 .titinelove{
    margin-top: 14%;
    height: 44vh;
width: 48%;
 }

 .card-text-date{
    height: 54vh;

 }

 
 .card-couple{

margin-top: 15%;

 }

 .titre-couple{

margin-top: 5%;
 }

 .titine-couple{
margin-top: 15%;
height: 52vh;
width: 43%;
margin-left: -2%;

 }


 .titine-saint-valentin{
margin-right: 10px;
width: 45%;
    margin-top: 4%;
 }

 .card-mariage{

    height: 55vh;
 }

 .photo-mariage{
margin-top: 4%;
    height: 60%;
    width:45%;
    margin-left: -5%;
 }


 .titine-calin{
width: 42%;
    height: 87vh;
 }



 .km-amour{

    left: 10%;


 }


 .titine-km{
width: 40%;
height: 80vh;
margin-left: -4%;
 }




 .amour-chemin{
left: 24%;
width: 100%;
    margin-top: -1%;
 }

 .titine-main{
width: 40%;
height: 79vh;
right: 6%;
 }


 .Onira{
    margin-top: -15%;
 }

 .Titine-parole{
margin-top: -14%;
height: 95vh;
font-size:20px ;

 }

 .titine-parole-two{
    margin-top: -14%;
    height: 95vh;
    font-size: 21px;

 }

 .container-tshirt img{

    width: 40%;
 }


 .container-bob img{


    width: 41%;
 }



 .container-forever img{


    width: 65%;
 }
}