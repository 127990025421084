.background-video-container {
  position: relative;
  height: 89vh;
  overflow: hidden;
}

.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.contente {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 20px;
  background: rgba(0, 0, 0, 0.8);
  margin: auto;
  max-width: 810px;
  border-radius: 10px;
  top: 5%;
  height: 75vh;
  overflow-y: auto; 
  line-height: 0.4; /* Adjusted for better readability */
  color: #dfdfdf;
  opacity: 0; /* Start hidden */
  animation: fadeIn 2s forwards; 
font-family:'Times New Roman', Times, serif;
  font-size: 1.2em; /* Utiliser des unités relatives */
  font-style: italic;
  font-size: 140%;
}

@keyframes blinke {
  0%, 100% {
    color: #dfdfdf;
  }
  50% {
    color: #ff0000;
  }
}

.custom-icon {
  justify-content: flex-end;
  flex-direction: row;
  cursor: pointer;
  color: #dfdfdf;
  background-color: #0000005a;
  padding: 15px;
  border-radius: 25px;
  width: 20px;
  margin: 5px;
}


.custom-icon.blinkeing {
  animation: blinke 5s infinite;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.line {
  opacity: 0; /* Start hidden */
  animation: fadeIn 4s forwards; /* Animation lasting 1 second */
}
.line-delay-1 { animation-delay: 1s; }
.delay-2 { animation-delay: 2s; }
.delay-3 { animation-delay: 3s; }
.delay-4 { animation-delay: 4s; }
.delay-5 { animation-delay: 5s; }
.delay-6 { animation-delay: 6s; }
.delay-7 { animation-delay: 7s; }
.delay-8 { animation-delay: 8s; }
.delay-9 { animation-delay: 9s; }
.delay-10 { animation-delay: 10s; }
.delay-11 { animation-delay: 11s; }
.delay-12 { animation-delay: 12s; }
.delay-13 { animation-delay: 13s; }
.delay-14 { animation-delay: 14s; }
.delay-15 { animation-delay: 15s; }
.delay-16 { animation-delay: 16s; }
.delay-17 { animation-delay: 17s; }
.delay-18 { animation-delay: 18s; }
.delay-19 { animation-delay: 19s; }
.delay-20 { animation-delay: 20s; }
.delay-21 { animation-delay: 21s; }
.delay-22 { animation-delay: 22s; }
.delay-23 { animation-delay: 23s; }
.delay-24 { animation-delay: 24s; }
.delay-25 { animation-delay: 25s; }
.delay-26 { animation-delay: 26s; }
.delay-27 { animation-delay: 27s; }
.delay-28 { animation-delay: 28s; }
.delay-29 { animation-delay: 29s; }
.delay-30 { animation-delay: 30s; }
.delay-31 { animation-delay: 31s; }
.delay-32 { animation-delay: 32s; }
.delay-33 { animation-delay: 33s; }
.delay-34 { animation-delay: 34s; }
.delay-35 { animation-delay: 35s; }
.delay-36 { animation-delay: 36s; }
.delay-37 { animation-delay: 37s; }
.delay-38 { animation-delay: 38s; }
.delay-39 { animation-delay: 39s; }
.delay-40 { animation-delay: 40s; }
.delay-41 { animation-delay: 41s; }
.delay-42 { animation-delay: 42s; }
.delay-43 { animation-delay: 43s; }
.delay-44 { animation-delay: 44s; }
.delay-45 { animation-delay: 45s; }
.delay-46 { animation-delay: 46s; }
.delay-47 { animation-delay: 47s; }
.delay-48 { animation-delay: 48s; }
.delay-49 { animation-delay: 49s; }
.delay-50 { animation-delay: 50s; }

.colorbold {
  color: #ff0000;
}


    
    

@media (max-width: 480px) {
  .bodyaccident{
    overflow: hidden; /* Empêche le défilement de la page entière */
   
  }

  .contente {
    padding: 8px; /* Réduire l'espacement intérieur pour les téléphones */
    font-size: 16px; /* Encore réduire la taille de la police pour les petits écrans */
    width: 95%;
    padding-top: 20px;
    height: 68vh; /* Prend toute la hauteur de la fenêtre */
    overflow-y: auto; 
  

  }

  .background-video-container {
    margin-top: -9px;
    position: relative;
    height: 78vh;

  }

  p.line {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.5; /* Ajuste la hauteur de ligne si nécessaire */
   font-family: 'Times New Roman', Times, serif;
   font-style: italic;
   font-size: 100%;
   

  }
  .line-delay-1{
 
   font-family: 'Times New Roman', Times, serif;
   font-style: italic;
   font-size: 170%;
   margin-top: 10%;
    
  }


  .custom-icon {
    width: 7%;
    height: 3vh;
    border-radius: 140%;
    padding: 5%;
    margin-bottom: -5px;

  }
}


@media only screen and (min-width: 1280px) and (max-width: 1440px) {

  
  .background-video {
   
    z-index: 1;
  }
  .custom-icon {
    justify-content: flex-end;
    flex-direction: row;
    cursor: pointer;
    color: #dfdfdf;
    background-color: #0000005a;
    padding: 15px;
    border-radius: 25px;
    width: 20px;
    margin: 5px;
  }
  


}

/* ipad pro */
@media only screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .custom-icon {
    justify-content: flex-end;
    flex-direction: row;
    cursor: pointer;
    color: #dfdfdf;
    background-color: #0000005a;
    padding: 20px;
    border-radius: 100%;
    width: 39px;
    margin: 5px;
    height: 35px;
  }

 

  .contente{
    font-size: 25px;
  }
}


@media screen and (min-width: 2020px) and (max-width: 2879px) {


 .contente {
  font-size: 29px;
padding: 3%;
height: 72vh;
}

}



@media only screen and (min-device-width: 393px) and (-webkit-device-pixel-ratio: 3) {

  .contente{

    font-size:110%;
  }

}

  
  

