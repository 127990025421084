/* Styles de base */
.titrepoème {
  text-align: center;
  color: #dfdfdf;
  font-family: 'Enchanted Tales';
  line-height: 1.2;
  font-style: italic;
  font-size: 2em; 
}

.titrepoème span {
  display: inline-block;
  opacity: 0;
}

.poème {
  text-align: center;
  color: #dfdfdf;
  font-family: 'Times New Roman', Times, serif;
  font-size: 1.2em;
  font-style: italic;
}

.poème span:hover, .titrepoème span:hover {
  color: #d4af37;
  cursor: pointer;
}

.poème span {
  display: inline-block;
  opacity: 1;
}

/* Animation du texte */
@keyframes slideInRandom {
  from {
    transform: translate(var(--fromX, 0), var(--fromY, 0));
    opacity: 0;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}

.slideInRandom {
  animation: slideInRandom 7s forwards;
}

@keyframes blink {
  0%, 100% {
    color: #dfdfdf;
  }
  50% {
    color: #ff0000;
  }
}

/* Conteneur du poème */
.poemContainer {
  height: 85vh;
  margin: auto;
  border: 0.2em solid #dfdfdf;
  animation: borderExpand 7s linear forwards, expandContainer 7s linear forwards;
  overflow: hidden;
  border-radius: 1%;
  box-sizing: border-box;
  box-shadow:
    0 0 0.6em 0em #dfdfdf, 
    0 0 0.9em 0em #dfdfdf,
    inset 0 0 0.0em #dfdfdf,
    inset 0 0 0.6em 0.0em #dfdfdf,
    inset 0 0 0.7em 0.0em #dfdfdf;
}

/* Animations du conteneur */
@keyframes expandContainer {
  0% {
    width: 30%;
    top: 0%;
  }
  100% {
    width: 50%;
    top: 0%;
  }
}

@keyframes borderExpand {
  0% {
    border-color: transparent;
  }
  100% {
    border-color: #dfdfdf;
  }
}

/* Texte du poème */
.je { animation: slideInRandom 7s forwards, blink 5s 25s infinite; }
.suis { animation: slideInRandom 7s forwards, blink 5s 30s infinite; }
.amoureux { animation: slideInRandom 7s forwards, blink 5s 35s infinite; }
.de { animation: slideInRandom 7s forwards, blink 5s 40s infinite; }
.titine { animation: slideInRandom 7s forwards, blink 5s 45s infinite; }
.v { animation: slideInRandom 7s forwards, blink 5s 50s infinite; }
.et { animation: slideInRandom 7s forwards, blink 5s 75s infinite; }
.De { animation: slideInRandom 7s forwards, blink 5s 80s infinite; }
.s { animation: slideInRandom 7s forwards, blink 5s 85s infinite; }

/* Media queries pour différents tailles d'écran */
@media (min-width: 1025px) and (max-width: 1366px) {
  .poemContainer {
    margin-top: 20px;
    height: 84vh;
    width: 65%;
    transform: scaleX(1);
  }
}

@media (max-width: 480px) {
  .poemContainer {
    margin-top: 5px;
    height: 77vh !important;
    width: 100% !important;
  }

  .poème {
    font-size: 88% !important;
    

  }

  .titrepoème {
    font-size: 150% !important;
    font-family: 'Times New Roman', Times, serif;

  }
}

@media only screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .poemContainer {
    margin-top: 15px;
    height: 80vh !important;
    width: 55% !important;
    overflow-y: auto;
    font-size: 17px;
  }
}



@media screen and (min-width: 1890px) and (max-width: 2879px) {
  .poème {
    margin-top: -13px;
  }
}

@media only screen and (max-width: 1670px) {
  .poème {
    font-size: 16px;
    margin-top: -15px;
  }
}



/* Ajout de media queries pour d'autres résolutions de PC */

/* Écrans de très haute résolution (2560px à 3840px de large) */
@media screen and (min-width: 2560px) and (max-width: 3840px) {
  .poème {
    font-size: 2em;
    margin-top: -10px;
  }

  .titrepoème {
    font-size: 2.5em;
    margin-top: 20px;
  }

  .poemContainer {
    width: 50%;
    height: 85vh;
  }
}

/* Écrans entre 1680px et 1920px de large */
@media screen and (min-width: 1680px) and (max-width: 1920px) {
  .poème {
    font-size: 19px;
    margin-top: -12px;
  }

  .titrepoème {
    
    margin-top: 18px;
  }

  .poemContainer {
    width: 60%;
    height: 86vh;
  }
}

/* Écrans entre 1440px et 1680px de large */
@media screen and (min-width: 1440px) and (max-width: 1600px) {
  .poème {
    font-size: 16px;
    margin-top: -15px;
  }

  .titrepoème {
    font-size: 1.8em;
    margin-top: 17px;
  }

  .poemContainer {
    width: 65%;
    height: 88vh;
  }
}

/* ipad pro */
@media screen and (min-width: 1366px) and (max-width: 1440px) {
  .poème {
    font-size: 18px !important;
    margin-top: -17px;
  }

  .titrepoème {
    font-size: 1.7em;
    margin-top: 16px;
  }

  .poemContainer {
    width: 68%;
    height: 88vh;

  }
}

/* Écrans plus petits entre 1024px et 1280px de large */
@media screen and (min-width: 1024px) and (max-width: 1280px) {
  .poème {
    font-size: 1.0em;
    margin-top: -20px;
  }

  .titrepoème {
    font-size: 1.5em;
    margin-top: 15px;
  }

  .poemContainer {
    width: 70%;
    height: 83vh;
  }
}


@media screen and (min-width: 2020px) and (max-width: 2879px) {

  .poème{

    font-size: 26px;
  }

}


@media only screen and (min-device-width: 414px) and (max-device-width: 414px) and (-webkit-device-pixel-ratio: 2) {
  .poème {
   font-size: 15px ;
    margin-top: -5px;
  }

  .titrepoème {
    font-size: 3em;
    margin-top: 25px;
  }

  .poemContainer {
    width: 45%;
    height: 86vh !important;
   
  }

}

@media only screen and (min-width: 768px) and (max-width: 1359px){

  
  .poème {
    font-size: 17px ;
     margin-top: -15px;
   }
 
   .titrepoème {
     font-size: 2em;
     margin-top: 12px;
   }
 
   .poemContainer {
   
      margin-top: 15px;
      width: 55% !important;
      overflow-y: auto;
      
    
    }
  }