.slider-container {
    position: relative;
    width: 100%;
    max-width: 1100px; /* Ajustez la largeur selon vos besoins */
    margin: auto;
  }

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    color: #DFDFDF;
    font-size: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    border-radius: 60%;
padding: 10px;
    font-size: 34px;
  width: 30px;
height:30px;
  }
  
  .arrow.left {
    left: 15px;
  }
  
  .arrow.right {
    right: 15px;

  }


.infocontainer {
    color: aliceblue;
    position: absolute;
    
}

.title{
  margin-top: 11%;
}
.Title {
    font-size: 2rem;
    color: #DFDFDF;
   
  margin-top: 11px;
}

  .imgContainer img{
    width: auto;
    height: auto;
    border-radius: 1%;
  }
  
.slide {
    display: flex;
    justify-content: center;
    align-items: center;

        position: relative;
       

}



.control-arrow {

    display: none;
}


@media (min-width: 1025px) and (max-width: 1366px) {
  .title{
    margin-top: 18%;
  }}


  
  @media (max-width: 480px) {

    .slider-container {
      position: relative;
      width: 100%;
      max-width: 1100px; /* Ajustez la largeur selon vos besoins */
      margin: auto;
    }
  
  
    
    .arrow.left {
      left: 1px;
    }
    
    .arrow.right {
      right: 1px;
  
    }
  
  
 
  
  .title{
    margin-top: 30%;
  }
  .Title {
      font-size: 22px;
      color: #DFDFDF;
  }
  
  }

  @media only screen and (min-width: 1280px) and (max-width: 1440px) {
    .slider-container {
    margin-top: -45px;
      width: 65%;
   
    }
  
.title {
  
margin-top: 120px;
}
  }

