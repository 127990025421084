/* Style par défaut pour le header */
header {
  width: 100%;
  height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #DFDFDF;
  background-color: #000000;
  font-size: 19px;
  padding: 0 20px;
  box-sizing: border-box;
}

.slogan {
  margin-top: 16px;
  margin-left: 1%;
  width: 70%;
 flex: 1; /* Pour occuper l'espace disponible */
}

.page {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-right: 3%;
  font-family: "Enchanted Tales";
  font-size: 20px;
}

.page p {
  margin: 10px;
}

.page a {
  color: #DFDFDF;
  text-decoration: none;
  position: relative;
}

.page a::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: #DFDFDF;
  transform-origin: bottom right;
  transition: transform 0.35s ease-out;
}

.page a:hover::after {
  transform: scaleX(1);
  transform-origin: center center;
  color: #DFDFDF;
}

/* Hover effect for page p elements except the third child */
.page p:not(:nth-child(3)):hover {
  color: #D4AF37;
  cursor: pointer;
}



/* Pour les écrans de moins de 480px */
@media (max-width: 480px) {
  body {
    overflow-x: hidden;
    width: 100%;
  }

  header {
    height: 16vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .slogan {
    margin: 0; /* Suppression des marges */
    text-align: center; /* Centrer le texte */
    width: 100%;
    position: absolute;
    top: 30%; /* Centrer verticalement */
    left: 50%;
    transform: translate(-50%, -50%); /* Centrer horizontalement */
  }

  .page {
    font-size: 18px;
    margin: 0 auto; /* Centrer la page horizontalement */
    font-family: 'Times New Roman', Times, serif;
    text-align: center; /* Centrer le contenu de la page */
    margin-top: 16%;
  }
  .page a::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -1px;
    left: 0;
    background-color: #DFDFDF;
    transform-origin: bottom right;
    transition: transform 0.35s ease-out;
  }
  

}


 /* Ipad 13 pouces*/
 @media only screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  header {
  
    height: 10vh !important;
    
  }
  
  .page p{
  
    width: 100%;
  
    font-size: 10px;


  }
 
  .page a::after {
margin-bottom:  5px;
margin-left: 2px;
  }
  .slogan {
    margin-top: 16px;
    margin-left: 1%;
    height: 68px;
    width: auto; 
  
  }


}




/* lenovo 15*/
@media only screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: landscape) {
  header {
  
    height: 13vh ;
    
  }
  
  .page p{
   
    width: 100%;
    margin-right: 3%;
    font-size: 20px;


  }
  .page p {
    margin: 8px;
  }
  .page a::after {
margin-bottom:  5px;
margin-left: 2px;
  }
  .slogan {
    margin-top: 16px;
    margin-left: 1%;
    height: 68px;
    width: auto; 
  
  }
}

