.custom-toast {
    color: black !important; /* Texte blanc pour la lisibilité */
    font-weight: bold; /* Style de texte gras */
    margin-bottom: 40px;
    background-color: #DFDFDF !important; /* Fond rouge */
}


.Toastify__toast-container{
    height: 5vh;
    margin-bottom: 4%;
}


.custom-toast-container-two{
margin-bottom: 2%;

}

.custom-toast-two{
    background-color: rgba(0, 0, 0, 0.8);
color: #ffffff;
border: 0.1em solid #DFDFDF;
    font-weight: bold; /* Style de texte gras */

    svg{
        color: #DFDFDF;
    }
}





.custom-toastbook{
    margin-top: 14%;
    color: black !important; /* Texte blanc pour la lisibilité */
    font-weight: bold; /* Style de texte gras */
    background-color: #DFDFDF !important; /* Fond rouge */
    path{
        color: #000000;
    }
}


@media (max-width: 480px) {


    .custom-toast-two{
        background-color: rgba(0, 0, 0, 0.8);
    color: #ffffff;
    border: 0.1em solid #DFDFDF;
    margin-top: -30px;
        font-weight: bold; /* Style de texte gras */
        position: absolute;
top: -130%;
left: 5%;
width:90%;
border-radius: 10px;
    
        svg{
            color: #DFDFDF;
        }


}
.custom-toastbook{

position: absolute;
top: -180%;
left: 5%;
width:90%;
border-radius: 10px;
}

.custom-toast {
    position: absolute;
    top: -180%;
    left: 5%;
    width:90%;
    border-radius: 10px;
}


.Toastify__toast-container{
    height: 5vh;
    margin-bottom: 4%;

}


.custom-toast-container-two{
margin-bottom: 2%;

}






}











